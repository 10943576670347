// lib/useWorkspaceStore.js
import { create } from 'zustand';

const useWorkspaceStore = create((set) => ({
  workspaceId: null,
  workspaceUrlSlug: null,
  workspaceName: null,
  workspaceLogo: null, // workspaceLogo state
  workspaceDescription: null, // Add workspaceDescription state
  setWorkspaceId: (id) => set({ workspaceId: id }),
  setWorkspaceUrlSlug: (slug) => set({ workspaceUrlSlug: slug }),
  setWorkspaceName: (name) => set({ workspaceName: name }),
  setWorkspaceLogo: (logo) => set({ workspaceLogo: logo }), // Setter for workspaceLogo
  setWorkspaceDescription: (description) =>
    set({ workspaceDescription: description }), // Setter for workspaceDescription
}));

export default useWorkspaceStore;
